import React from "react"
import Helmet from "react-helmet"

const Twitter = ({ seo }) => (
  <Helmet>
    {seo.twitterName && (
      <meta name="twitter:creator" content={seo.twitterName} />
    )}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={seo.title} />
    <meta name="twitter:description" content={seo.description} />
    <meta name="twitter:image" content={seo.image} />
    <meta name="twitter:image:alt" content={seo.description} />
  </Helmet>
)

export default Twitter
