import React from "react"
import Helmet from "react-helmet"

const Facebook = ({ seo }) => {
  return (
    <Helmet>
      {seo.facebookName && (
        <meta property="og:site_name" content={seo.facebookName} />
      )}
      <meta property="og:locale" content={seo.ogLanguage} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:image:alt" content={seo.description} />
    </Helmet>
  )
}

export default Facebook
