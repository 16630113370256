import React from "react"

import "../sass/style.scss"

export default ({ children }) => (
  <>
    <div className="container">
      <main>{children}</main>
    </div>
  </>
)
