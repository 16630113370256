import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../layouts/mainLayout"
import SEO from "../components/SEO"

const HomePage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HomePage {
      logo: contentfulAsset(title: { eq: "Iowa Booch" }) {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      featureImage: contentfulAsset(title: {eq: "Iowa Booch Home Page"}) {
        fluid {
          src
        }
      }
    }
  `)

  const logo = data.logo
  const featureImage = data.featureImage.fluid.src

  return (
    <Layout>
      <SEO
        title="Kombucha Made in Dubuque, Iowa"
        description="Iowa Booch is kombucha 100% made in Iowa. We even sell supplies for you to start your own"
        featureImage={featureImage}
        pathname={location.href}
      />
      <div className="landing_page">
        <div className="landing_page_item">
          <h1>Iowa Booch</h1>
          <h2>Kombucha Made with the Heart of Iowa</h2>
          <Img fluid={logo.fluid} alt={logo.title} />
          <h4 className="text-center">Website Coming Soon</h4>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
