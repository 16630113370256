import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Facebook from "./facebook"
import Twitter from "./twitter"

const SEO = ({ title, description, featureImage, pathname }) => {
  const { site } = useStaticQuery(query)

  if (title.length <= 49) {
    title = ` ${title} | Iowa Booch`
  } else {
    title
  }

  const seo = {
    title: title,
    description: description,
    image: featureImage,
    url: pathname || "",
    ogLanguage: "en",
    twitterName: "@iowabooch",
    facebookName: "iowabooch",
  }

  return (
    <>
      <Helmet title={title}>
        <html lang={seo.ogLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <Facebook seo={seo} />
      <Twitter seo={seo} />
    </>
  )
}
export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  logo: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.any,
  recipe: PropTypes.array,
  homePage: PropTypes.bool,
  node: PropTypes.object,
  crumbs: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  logo: null,
  pathname: null,
  article: null,
  recipe: null,
  homePage: false,
  node: null,
  crumbs: null,
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
    }
  }
`
